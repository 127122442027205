<template>
    <div class="animated fadeIn">
      <b-row>
        <b-col md="2"></b-col>
        <b-col md="8">
            <b-card>
        <loading
          :active.sync="isLoading"
          :is-full-page="false"
          :color="this.$config.LOADER.color"
          :opacity="this.$config.LOADER.opacity"
          :background-color="this.$config.LOADER.backgroundColor"
          :z-index="10000"
          :width="30"
          :height="30"
        ></loading>
        <div>
            <b-form @submit.stop.prevent="checkCitizen">
            <h4>Шинэ хэрэглэгч бүртгэх</h4>
            <hr>
            <b-row>
                <b-col sm="6">
                    <b-form-group
                        id="input-group-lastname"
                        label="Овог"
                        label-for="input-spec"
                        >
                        <b-form-input
                            id="input-spec"
                            v-model="form.lastname"
                            placeholder="Овог"
                            required
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group
                        id="input-group-firstname"
                        label="Нэр"
                        label-for="input-spec"
                        >
                        <b-form-input
                            id="input-spec"
                            v-model="form.firstname"
                            placeholder="Нэр"
                            required
                        ></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="6">
                    <b-form-group label="Регистр:" label-for="input-change-phone">
                        <b-input-group>
                            <b-input-group-append>
                                <b-select class="rounded-0" v-model="rd.char1"  :options="chars.split('')"></b-select>
                            </b-input-group-append>
                            <b-input-group-append>
                                <b-select class="rounded-0" v-model="rd.char2"  :options="chars.split('')"></b-select>
                            </b-input-group-append>
                            <b-form-input maxlength="8" pattern="\d{8}" id="input-change-phone" v-model="rd.number" required placeholder="Регистр" @keypress="isNumber($event)"></b-form-input>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group
                        id="input-group-email"
                        label="Имэйл"
                        label-for="input-spec"
                        >
                        <b-form-input
                            type="email"
                            id="input-spec"
                            v-model="form.email"
                            placeholder="Имэйл"
                            required
                        ></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="6">
                    <b-form-group
                        id="input-group-lastname"
                        label="Нийгмийн даатгал төлдөг эсэх"
                        label-for="input-spec"
                        >
                        <b-form-select
                            id="input-savingBank"
                            class="form-control"
                            v-model="form.isPaidNd"
                            :options="options.yes_and_no"
                            required
                        ></b-form-select>
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group
                        id="input-group-firstname"
                        label="Боловсрол"
                        label-for="input-spec"
                        >
                        <b-form-select
                            id="input-savingBank"
                            class="form-control"
                            v-model="form.education"
                            :options="options.educations"
                            required
                        ></b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <hr>
            <h4>Яаралтай үед холбоо барих</h4>
            <b-row>
                <b-col sm="6">
                    <b-form-group
                        id="input-group-lastname"
                        label="Овог"
                        label-for="input-spec"
                        >
                        <b-form-input
                            id="input-spec"
                            v-model="form.emergencyLastname"
                            placeholder="Овог"
                            required
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group
                        id="input-group-firstname"
                        label="Нэр"
                        label-for="input-spec"
                        >
                        <b-form-input
                            id="input-spec"
                            v-model="form.emergencyFirstname"
                            placeholder="Нэр"
                            required
                        ></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="6">
                    <b-form-group label="Регистр:" label-for="input-change-phone">
                        <b-input-group>
                            <b-input-group-append>
                                <b-select class="rounded-0" v-model="rd2.char1"  :options="chars.split('')"></b-select>
                            </b-input-group-append>
                            <b-input-group-append>
                                <b-select class="rounded-0" v-model="rd2.char2"  :options="chars.split('')"></b-select>
                            </b-input-group-append>
                            <b-form-input maxlength="8" pattern="\d{8}" id="input-change-phone" v-model="rd2.number" required placeholder="Регистр" @keypress="isNumber($event)"></b-form-input>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group
                        id="input-group-email"
                        label="Утасны дугаар"
                        label-for="input-spec"
                        >
                        <b-form-input
                            maxlength="8" pattern="\d{8}"
                            v-model="form.emergencyNumber"
                            equired placeholder="Утасны дугаар" @keypress="isNumber($event)"
                            required
                        ></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-button variant="primary" type="submit">Хадгалах</b-button>
                </b-col>
            </b-row>
            </b-form>
        </div>
        
      </b-card>
        </b-col>
      </b-row>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  const username = 'numur';
const password = '20240902HahaDD$';
const base64Credentials = btoa(`${username}:${password}`);

  export default {
    name: "Customers.Create",
    data: function() {
      return {
        form: {
            email: '',
            firstname: '',
            lastname: '',
            registerNumber: '',
            education: 1,
            info: null,
            isPaidNd: 0,
            isUb: 1,
            emergencyNumber: '',
            emergencyLastname: '',
            emergencyFirstname: '',
            emergencyRegisterNumber: ''
        },
        isLoading: false,
        rd: {
            char1: 'А',
            char2: 'А',
            number: ''
        },
        rd2: {
            char1: 'А',
            char2: 'А',
            number: ''
        },
        chars: 'АБВГДЕЁЖЗИЙКЛМНОӨПРСТУҮФХЦЧШЩЪЫЬЭЮЯ',
        options: {
            yes_and_no: [
                {
                    value: 1,
                    text: 'Тийм'
                },
                {
                    value: 0,
                    text: 'Үгүй'
                }
            ],
            educations: [
                {
                    value: 1,
                    text: 'Боловсролгүй'
                },
                {
                    value: 2,
                    text: 'Бага'
                },
                {
                    value: 3,
                    text: 'Суурь'
                },
                {
                    value: 4,
                    text: 'Бүрэн дунд'
                },
                {
                    value: 5,
                    text: 'Техник мэргэжлийн'
                },
                {
                    value: 6,
                    text: 'Дээд'
                },
                {
                    value: 7,
                    text: 'Магистр ба түүнээс дээш'
                }
            ]
        }
      };
    },
    watch: {
        rd: {
            handler (rd) {
                this.$data.form.registerNumber = rd.char1 + rd.char2 + rd.number
            },
            deep: true
        },
        rd2: {
            handler (rd2) {
                this.$data.form.emergencyRegisterNumber = rd2.char1 + rd2.char2 + rd2.number
            },
            deep: true
        },
    },
    created: function() {
      if (!this.checkPermission("admin.customer.create")) {
        this.$router.push("/dashboard");
      }
    },
    methods: {
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        },
        save: function() {
            this.isLoading = true;
            
            this.$http
                .post(
                this.$config.API_URL + "CustomerWebService/create",
                {
                    data: JSON.stringify(this.$data.form),
                },
                {
                    headers: this.$store.getters.httpHeader,
                    emulateJSON: true,
                }
                )
                .then(
                (response) => {
                    console.log(response)
                    this.isLoading = false;
                    if(response.body.responseResultType == 'SUCCESS') {
                        let route = this.$router.resolve({ path: "/admin/customers/" + response.body.successData + "/detail" });
                        window.open(route.href);
                    } else {
                        let _ms = response.body.failureMessages.message;
                        for(var i in _ms) {
                            this.showToast('Анхаар', _ms[i].failureMessage, 'warning')
                        }
                    }
                },
                (response) => {
                    this.isLoading = false;
                }
            );
        },
        checkCitizen() {
            this.isLoading = true;
            console.log({
                lastname: this.form.lastname,
                firstname: this.form.firstname,
                register: this.form.registerNumber
            });

            // Create the payload for the POST request
            const payload = {
                last_name: this.form.lastname,
                first_name: this.form.firstname,
                regnum: this.form.registerNumber
            };

            // Send POST request using Axios with Basic Authentication header
            axios.post(this.$config.NUMUR_MS_ADMIN_URL + 'check_citizen', payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Basic ${base64Credentials}` // Add Basic Auth header
                }
            })
            .then((response) => {
                console.log(response);
                this.isLoading = false;
                if (response.data.success) {
                    // If the success flag is true, show the message
                    this.showToast('Анхаар', response.data.data.message, 'success');
                    this.checkCitizenEmergency();
                } else {
                    // If the success flag is false, display an error message
                    this.showToast('Алдаа', 'Таны мэдээлэл буруу байна', 'danger');
                }
            })
            .catch((error) => {
                this.isLoading = false;
                console.error('Error checking citizen:', error);
                this.showToast('Алдаа', 'Шинэ хэрэглэгч нэр, регистер таарахгүй байна', 'danger');
            });
        },

checkCitizenEmergency() {
    this.isLoading = true;

    const payload = {
        last_name: this.form.emergencyLastname.trim(), // Trim any spaces
        first_name: this.form.emergencyFirstname.trim(), // Trim any spaces
        regnum: this.form.emergencyRegisterNumber.trim() // Trim any spaces
    };

    // Send POST request for emergency check
    axios.post(this.$config.NUMUR_MS_ADMIN_URL + 'check_citizen', payload, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${base64Credentials}`
        }
    })
    .then((response) => {
        console.log(response);
        this.isLoading = false;
        if (response.data.success) {
            // If success is true, save
            this.showToast('Амжилт', response.data.data.message, 'success');
            this.save();
        } else {
            // If failure, show error message
            this.showToast('Алдаа', 'Таны мэдээлэл буруу байна', 'danger');
        }
    })
    .catch((error) => {
        this.isLoading = false;
        console.error('Error checking emergency citizen:', error);
        this.showToast('Алдаа', 'Яаралтай үед холбоо барих Нэр, регистер таарахгүй байна', 'danger');
    });
}
    },
  };
  </script>
  